/* eslint-disable import/prefer-default-export */

import { SvgIconProps, Theme } from '@mui/material'
import scholasticTheme, {
  adminScholasticTheme,
} from '../../theme/scholasticTheme'
import { ScholasticWordmarkLogo } from '../logos/ScholasticWordmarkLogo'
import ScholasticThemeProvider from '../ScholasticThemeProvider/ScholasticThemeProvider'

export type UniversalFundConfig = {
  companyName: string
  fundName: string
  shortName: string
  addressPlaceholder: string
  faviconPath: string
  logo: React.FC<SvgIconProps>
  logoWidthToHeight: number
  supportEmail: string
  supportPhone: string
  prettyPhone: string
  theme: Theme
  adminTheme: Theme
  themeProvider: React.FC<{ children: React.ReactNode; isAdmin?: boolean }>

  defaultDistributionFactor: number
  defaultOnboardingFee: number
  defaultClosingAndLegalFee: number
  defaultPricePerShare: number
  sharePriceLastUpdated: string
  managementFee: number
  defaultFundEquityPercentTarget: number
  fundStartingYear: number
  fundStartingQuarter: number
  validStates: string[]
  mapboxStyles: string

  defaultSalesOperator: string
  calendlyScheduleURL: string
  calendlyLandingURL: string
}

export type EnvFundConfig = {
  homeUrl: string
  offerUrl: string
}

export type FundConfig = UniversalFundConfig & EnvFundConfig

const scholasticFundConfiguration: UniversalFundConfig = {
  companyName: 'Scholastic Capital',
  fundName: 'Scholastic Capital 721 Fund',
  shortName: 'scholastic',
  faviconPath: 'src/images/favicon.png',
  addressPlaceholder: '2565 El Rancho Dr, Brookefield WI 53005',
  logo: ScholasticWordmarkLogo,
  logoWidthToHeight: 9.09,
  supportEmail: 'investorcare@scholasticcapital.com',
  supportPhone: '+17083847911',
  prettyPhone: '(708) 384-7911',
  theme: scholasticTheme,
  adminTheme: adminScholasticTheme,
  themeProvider: ScholasticThemeProvider,

  defaultDistributionFactor: 0.05,
  defaultOnboardingFee: 0.03,
  defaultClosingAndLegalFee: 0.01,
  defaultPricePerShare: 1000,
  sharePriceLastUpdated: 'Jun 30, 2023',
  managementFee: 0.01,
  defaultFundEquityPercentTarget: 1,
  fundStartingYear: 2021,
  fundStartingQuarter: 2,
  validStates: ['FL', 'TN'],
  mapboxStyles: 'mapbox://styles/flocktech/cm3gjmvq2000601sq3ya83a3u',

  defaultSalesOperator: 'Jacob Schnapp',
  calendlyScheduleURL:
    'https://calendly.com/sean-390/721-exchange-scholastic-capital',
  calendlyLandingURL:
    'https://calendly.com/sean-390/721-exchange-scholastic-capital',
}

export const scholasticStagingConfig = {
  homeUrl: 'https://stage-flockexchange.webflow.io',
  offerUrl: 'https://exchange-landing.stage-scholasticcapital.com',
}

export const scholasticProductionConfig = {
  homeUrl: 'https://exchange.scholasticcapital.com',
  offerUrl: 'https://exchange-landing.scholasticcapital.com',
}

// When we have more funds, this will switch between them
export const getFundConfig = () => {
  if (process.env.GATSBY_ENV === 'production') {
    return { ...scholasticFundConfiguration, ...scholasticProductionConfig }
  } else {
    return { ...scholasticFundConfiguration, ...scholasticStagingConfig }
  }
}
